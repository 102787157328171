import clsx from 'clsx';
import { HTMLAttributes } from 'react';

import { Image } from '@/components/image';

import styles from './OpenMobileApplicationBanner.module.scss';

export const OpenMobileApplicationBanner = ({
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={clsx(styles.wrapper, className)} {...rest}>
      <div className={styles.logo}>
        <Image src="/static/logo/azalianow-rounded-logo.svg" alt="Логотип AzaliaNow" fill />
      </div>
      <div className={styles.textBlock}>
        <span>AzaliaNow: доставка цветов</span>
        <a href="https://qr.azalianow.ru/universal/web-site-appstore-googleplay" target="_blank">
          Открыть в приложении
        </a>
      </div>
      <a
        className={styles.openButton}
        href="https://qr.azalianow.ru/universal/web-site-appstore-googleplay"
        target="_blank"
      >
        Открыть
      </a>
    </div>
  );
};
