import clsx from 'clsx';
import Link from 'next/link';

import { Image } from '@/components/image';
import {
  CatalogIcon,
  CheckoutIcon,
  FavoritesIcon,
  LoginIcon,
  OrdersIcon,
  ProfileIcon,
} from '@/components/layout/icons/menu/menu';
import { SearchBar } from '@/components/search-bar';
import MenuLink from '@/components/ui/menu/MenuLink';

import { analyticMetric } from '@/helpers/analytics-metric';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useHasMounted } from '@/hooks/useHasMounted';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { toggleDrawer } from '@/store/slices/drawer/drawer.slice';
import { changeLoginPage } from '@/store/slices/login-user/login-user.slice';

import { ROUTES } from '@/constants/routes.constant';

import stl from './HeaderMid.module.scss';

function HeaderMid({ className }: { className?: string }) {
  const hasMounted = useHasMounted();
  const login = useTypedSelector((state) => state.user.login);
  const dispatch = useAppDispatch();
  const products = useTypedSelector((state) => state.checkoutProducts.products);
  const favorites = useTypedSelector((state) => state.favoriteProducts.products);
  const isOpen = useTypedSelector((state) => state.drawer.isOpen);
  const { currentBonusCount } = useTypedSelector((state) => state.user);

  const onToggleDrawer = () => {
    analyticMetric.hitGoal('knopka-katalog');
    dispatch(toggleDrawer());
  };

  const openLoginModal = () => {
    if (hasMounted && !login) {
      dispatch(changeLoginPage(1));
    }
  };

  return (
    <div className={clsx(stl.container, className)}>
      <Link href={ROUTES.HOME} className={stl.logo}>
        <Image src={'/static/logo/azalianow-logo-red.svg'} fill priority alt="Логотип AzaliaNow" />
      </Link>

      <div className={stl.middle}>
        <button
          aria-label="Меню"
          className={clsx(stl.burger_menu, isOpen && stl.opened)}
          onClick={onToggleDrawer}
        >
          <div className={stl.burger_menu__icon}>
            <CatalogIcon />
          </div>
          <span>Каталог</span>
        </button>
        <SearchBar className={stl.search} />
      </div>

      <nav className={stl.menu}>
        <MenuLink
          href={ROUTES.CABINET.BONUSES}
          text="Азалия Коины"
          className={stl.menu_bonus}
          disabled={!(hasMounted && login)}
          icon={
            <span>
              {(hasMounted && currentBonusCount) || 'Получи BONUS'}{' '}
              <Image
                src={`/static/videos/azaliaicon.gif`}
                width={18}
                height={18}
                alt="1 Азалия Коин = 1 р."
              />
            </span>
          }
          onClick={openLoginModal}
        />
        <MenuLink
          text="Заказы"
          href={ROUTES.CABINET.ORDERS}
          testId="orders"
          disabled={!(hasMounted && login)}
          icon={<OrdersIcon />}
          onClick={openLoginModal}
        />
        <MenuLink
          text="Избранное"
          href={ROUTES.FAVORITES}
          testId="favorites"
          notEmpty={Boolean(favorites.length && hasMounted)}
          icon={<FavoritesIcon />}
        />
        <MenuLink
          text="Корзина"
          href={ROUTES.CHECKOUT}
          testId="checkout"
          notEmpty={Boolean(products.length && hasMounted)}
          icon={<CheckoutIcon />}
        />
        {login && hasMounted ? (
          <MenuLink
            text="Профиль"
            href={ROUTES.CABINET.PROFILE}
            testId="cabinet-prodile"
            className={stl.profile_icon}
            icon={<ProfileIcon />}
            onClick={() => analyticMetric.hitGoal('knopka-profil')}
          />
        ) : (
          <MenuLink
            text="Вход"
            testId="open-login-modal"
            className={stl.login_icon}
            disabled
            icon={<LoginIcon />}
            onClick={openLoginModal}
          />
        )}
        <button type="button" className={stl.btn_menu} onClick={onToggleDrawer}>
          Меню
        </button>
      </nav>
    </div>
  );
}

export default HeaderMid;
