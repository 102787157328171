import { useMotionValue, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';

let scrollThreshold = [0, 80];

export const useHeaderScroll = () => {
  const { scrollY } = useScroll();
  const scrollYOnDirectionChange = useRef(scrollY.get());
  const lastPixelsScrolled = useRef(0);
  const lastScrollDirection = useRef<'up' | 'down'>('down');
  const pixelsScrolled = useMotionValue(0);

  const headerBottomHeight = useTransform(pixelsScrolled, scrollThreshold, [59, 0]);
  const headerBottomY = useTransform(pixelsScrolled, scrollThreshold, ['0', '-59px']);

  scrollY.on('change', (latest) => {
    if (latest < 0) return;

    const isScrollingDown = (scrollY.getPrevious() as number) - latest < 0;
    const scrollDirection: 'up' | 'down' = isScrollingDown ? 'down' : 'up';
    const currentPixelsScrolled = pixelsScrolled.get();
    let newPixelsScrolled;

    if (lastScrollDirection.current !== scrollDirection) {
      lastPixelsScrolled.current = currentPixelsScrolled;
      scrollYOnDirectionChange.current = latest;
    }

    if (isScrollingDown) {
      newPixelsScrolled = Math.min(
        lastPixelsScrolled.current + (latest - scrollYOnDirectionChange.current),
        scrollThreshold[1]
      );
    } else {
      newPixelsScrolled = Math.max(
        lastPixelsScrolled.current - (scrollYOnDirectionChange.current - latest),
        scrollThreshold[0]
      );
    }

    pixelsScrolled.set(newPixelsScrolled);
    lastScrollDirection.current = scrollDirection;
  });

  return { headerBottomHeight, headerBottomY };
};
